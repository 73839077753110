import { useEffect, useState } from 'react'

import api from '@/api'
import Main from '@/pages/Main/Main'

import './App.css'

const EVENT_TYPE = 'nizhniy800'

function App() {
  const [city, setCity] = useState(null)
  const [formats, setFormats] = useState([])
  const [banners, setBanners] = useState([])

  useEffect(() => {
    api.get('/api/event_format/event/m52_2025_1&m52_2025_2').then((r) => {
      if (r?.status === 200 && r?.data?.values?.length > 0) {
        setFormats(r.data.values)
        setCity(r.data.city)

        if (r?.data?.city?.banners?.length > 0) {
          setBanners(r.data.city.banners)
        }
      } else {
        fetchBanners().then((r) => {
          if (r?.status === 200 && r?.data?.banners?.length > 0) {
            setBanners(r.data.banners)
          }
        })
      }
    })
  }, [])

  const fetchBanners = async () => {
    try {
      return await api.get(`api/event_city/banners/${EVENT_TYPE}`)
    } catch (error) {
      console.error(error)
    }
  }

  return <Main formats={formats} city={city} banners={banners} />
}

export default App
